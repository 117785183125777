<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title>{{ this.$t("Updatecoordinatevalue") }}</v-card-title>
    <v-container>
      <v-row class="text-center">
        <template v-if="this.checkNEZ">
          <v-col cols="15" sm="4">
            <div>Y {{ this.$t("Surveysystem") }}(m)</div>
          </v-col>
          <v-col cols="15" sm="4">
            <div>X {{ this.$t("Surveysystem") }}(m)</div>
          </v-col>
        </template>
        <template v-else-if="!this.checkNEZ">
          <v-col cols="15" sm="4">
            <div>Y {{ this.$t("Surveysystem") }}(m)</div>
          </v-col>
          <v-col cols="15" sm="4">
            <div>X {{ this.$t("Surveysystem") }}(m)</div>
          </v-col>
        </template>
        <v-col cols="15" sm="4">
          <div>Z (m)</div>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <template v-if="this.checkNEZ">
          <v-col cols="15" sm="4">
            <v-text-field variant="solo" :error="false" class="mt-2" v-model="coordinateY" :disabled="disableY" @update:model-value="($event) => checkCoordinatesValidity($event, 'y')"> </v-text-field>
          </v-col>
          <v-col cols="15" sm="4">
            <v-text-field variant="solo" class="mt-2" v-model="coordinateX" :disabled="disableX" @update:model-value="($event) => checkCoordinatesValidity($event, 'x')"></v-text-field>
          </v-col>
        </template>
        <template v-else-if="!this.checkNEZ">
          <v-col cols="15" sm="4">
            <v-text-field variant="solo" :error="false" class="mt-2" v-model="coordinateX" :disabled="disableX" @update:model-value="($event) => checkCoordinatesValidity($event, 'x')"></v-text-field>
          </v-col>
          <v-col cols="15" sm="4">
            <v-text-field variant="solo" class="mt-2" v-model="coordinateY" :disabled="disableY" @update:model-value="($event) => checkCoordinatesValidity($event, 'y')"> </v-text-field>
          </v-col>
        </template>
        <v-col cols="15" sm="4">
          <v-text-field variant="solo" class="mt-2" v-model="coordinateZ" :disabled="disableZ" @update:model-value="($event) => checkCoordinatesValidity($event, 'z')"></v-text-field>
        </v-col>
      </v-row>
      <p v-if="!isUpdateValid.x || !isUpdateValid.y || !isUpdateValid.z" class="pl-4" style="color: #f44336; font-size: 14px">Please enter valid input</p>
      <div class="d-flex text-center justify-space-around">
        <v-btn @click="confirmUpdate" class="px-9" color="primary" :disabled="!isUpdateValid.x || !isUpdateValid.y || !isUpdateValid.z">
          {{ this.$t("OK") }}
        </v-btn>
        <v-btn @click="cancelUpdate" class="" color="warning">
          {{ this.$t("CANCEL") }}
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import {
	convertCoordinateToCesiumCrs,
	convertCoordinateToPointCloudCrs,
} from "./../../utils/cesium-common";
import * as constants from "@/constant.js";

export default {
	name: "UpdateCtrlPointDialog",
	props: {
		id: String,
		pointId: String,
		entityUnderEdit: Number,
		disabilityConditions: String,
		origin: String,
	},
	beforeMount() {
		this.checkNEZ = this.$store.state.axis === "NEZ" ? true : false;
	},
	async mounted() {
		await this.initCoordinates();
	},
	data: () => ({
		x: 0,
		y: 0,
		z: 0,
		// prevPosition: null,
		isUpdateValid: { x: true, y: true, z: true },
		checkNEZ: null,
		disableX: false,
		disableY: false,
		disableZ: false,
	}),
	computed: {
		coordinateX: {
			get: function () {
				return this.checkInput(this.x);
			},
			set: function (str) {
				this.x = Number.parseFloat(str);
			},
		},
		coordinateY: {
			get: function () {
				return this.checkInput(this.y);
			},
			set: function (str) {
				this.y = Number.parseFloat(str);
			},
		},
		coordinateZ: {
			get: function () {
				return this.checkInput(this.z);
			},
			set: function (str) {
				this.z = Number.parseFloat(str);
			},
		},
	},
	methods: {
		checkInput(input) {
			if (Number.isNaN(input)) {
				return "";
			}
			const strVal = input.toString();
			return strVal.split(".")[1]
				? strVal.split(".")[1].length <= 3
					? strVal
					: input.toFixed(3)
				: strVal;
		},
		async initCoordinates() {
			this.x = 0;
			this.y = 0;
			this.z = 0;
			this.isUpdateValid = { x: true, y: true, z: true };
			this.$nextTick(async () => {
				// placement of Y and X text boxes
				this.checkNEZ = this.$store.state.axis === "NEZ" ? true : false;

				// disability conditions
				if (this.disabilityConditions === "Z") {
					this.disableX = false;
					this.disableY = false;
					this.disableZ = true;
				} else if (this.disabilityConditions === "XY") {
					this.disableX = true;
					this.disableY = true;
					this.disableZ = false;
				} else if (this.disabilityConditions === "NONE") {
					this.disableX = false;
					this.disableY = false;
					this.disableZ = false;
				}

				if (!this.pointId) return;

				let pos = undefined;

				if (this.origin === "CesiumView") {
					// function to get coordinates of point
					if (this.entityUnderEdit === constants.objectType.ROAD) {
						pos = window["viewer"].getCtrlPointCordiOnRoad(
							this.id,
							this.pointId,
						);
						pos = await convertCoordinateToPointCloudCrs(pos);
					}

					if (this.entityUnderEdit === constants.objectType.FLAT) {
						pos = window["viewer"].getCtrlPointCordiOnFlatGround(
							this.id,
							this.pointId,
						);
						pos = await convertCoordinateToPointCloudCrs(pos);
					}

					if (this.entityUnderEdit === constants.objectType.TRENCH) {
						pos = window["viewer"].getCtrlPointCordiOnTrench(
							this.id,
							this.pointId,
						);
						pos = await convertCoordinateToPointCloudCrs(pos);
					}
				} else if (this.origin === "AltitudeView") {
					if (this.entityUnderEdit === constants.objectType.ROAD) {
						const res = window["viewer"].getSlopeBreakPointInfoOnRoad(
							this.id,
							Number.parseInt(this.pointId),
						);
						pos = res.point;
					}

					// if (this.entityUnderEdit === constants.objectType.TRENCH) {
					//   const res = window["viewer"].getSlopeBreakPointInfoOnTrench(this.id, parseInt(this.pointId));
					//   pos = res.point;
					// }
				}

				if (!pos) {
					return;
				}

				this.x = pos.x;
				this.y = pos.y;
				this.z = pos.z;
			});
		},
		checkCoordinatesValidity(inputValue, prop) {
			this.isUpdateValid[prop] =
				inputValue && Number.isFinite(Number(inputValue));
		},
		cancelUpdate() {
			// This snippet is not required as now control point is updated on confirm click.
			// if (this.prevPosition) {
			//   this.entityUnderEdit === constants.objectType.ROAD
			//     ? window["viewer"].updateCtrlPointCordiOnRoad(this.id, this.pointId, this.prevPosition.x, this.prevPosition.y, this.prevPosition.z)
			//     : window["viewer"].updateCtrlPointCordiOnFlatGround(this.id, this.pointId, this.prevPosition.x, this.prevPosition.y, this.prevPosition.z);
			// }

			this.$emit("close");
		},
		async confirmUpdate() {
			if (
				this.isUpdateValid.x &&
				this.isUpdateValid.y &&
				this.isUpdateValid.z &&
				this.x !== undefined &&
				this.y !== undefined &&
				this.z !== undefined
			) {
				// ローディング開始
				await this.$store.dispatch("executeWithSpinner", async () => {
					try {
						const response = await convertCoordinateToCesiumCrs({
							x: this.x,
							y: this.y,
							z: this.z,
						});
						const outX = Number(response.x);
						const outY = Number(response.y);
						const outZ = Number(response.z);
						if (isNaN(outX) || isNaN(outY) || isNaN(outZ)) {
							throw new Error(
								`Input Point Could not be transformed into respective wgs84 points`,
							);
						}

						if (this.origin === "CesiumView") {
							// function to update coordinates this takes coordinates for cesium crs.
							if (this.entityUnderEdit === constants.objectType.ROAD) {
								window["viewer"].updateCtrlPointCordiOnRoad(
									this.id,
									this.pointId,
									outX,
									outY,
									outZ,
								);
							}

							if (this.entityUnderEdit === constants.objectType.FLAT) {
								if (this.disabilityConditions === "XY") {
									await window["viewer"].setFlatGroundPointsUniformHeight(
										this.id,
										Number.parseFloat(this.z),
									);
								} else if (this.disabilityConditions === "NONE") {
									await window["viewer"].updateCtrlPointCordiOnFlatGround(
										this.id,
										this.pointId,
										outX,
										outY,
										outZ,
									);
								}
							}

							if (this.entityUnderEdit === constants.objectType.TRENCH) {
								window["viewer"].updateCtrlPointCordiOnTrench(
									this.id,
									this.pointId,
									outX,
									outY,
									outZ,
								);
							}
						} else if (this.origin === "AltitudeView") {
							if (this.entityUnderEdit === constants.objectType.ROAD) {
								const slopeBreaks = window["viewer"].getSlopeBreaks(this.id);
								slopeBreaks[Number.parseInt(this.pointId)].height = this.z;
								window["viewer"].updateSlopeBreaksFromLongitudinalView(
									this.id,
									slopeBreaks,
								);
							}
						}
					} catch (e) {
						console.error(e);
					}
				});
			}
			this.$emit("close", false);
		},
	},
};
</script>
<style scoped></style>
