<template>
	<v-navigation-drawer class="drawer" permanent mobile-breakpoint="0" color="#17171a">
		<v-card color="#17171a" flat height="100%">
			<v-card-title class="pl-4 pt-6">
				<h1>{{ $t("PROJECT") }}</h1>
			</v-card-title>
			<v-card-text class="content-area">
				<v-row>
					<v-col cols="8">
						<v-text-field v-model="word" variant="solo" density="compact" :label="$t('list_search_title')" type="text"
							hide-details class="pt-10 pb-0 search-input" append-icon="mdi-magnify" @click:append="onSearchChange"
							@keyup.enter="getSitesEnter($event)"></v-text-field>
					</v-col>
					<v-col>
						<v-select v-model="limit" :items="numbers" :label="$t('number')" hide-details class="pt-10 pb-0"></v-select>
					</v-col>
				</v-row>
				<v-table class="pr-3 py-3 my-3 project-table">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left header-site-name">
									{{ $t("JOBSITE_NAME") }}
								</th>
								<th class="text-right"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="item in sites" :key="item.name" @mouseenter="on_mouseenter(item)"
								@mouseleave="on_mouseleave(item)" @click="on_click(item)">
								<td>{{ item.siteName }}</td>
								<td class="text-right pa-0">
									<v-btn color="primary" height="100%" @click="gotoProject(item)">
										{{ $t("OPEN") }}
										<v-icon size="small"> icon:Arrow-Right </v-icon>
									</v-btn>
								</td>
							</tr>
						</tbody>
					</template>
				</v-table>
				<v-pagination :length="pageCount" v-model="page" @update:model-value="getSites">
				</v-pagination>
			</v-card-text>
		</v-card>
		<v-dialog v-model="isSelectCoordinateDialogOpen" width="800px" v-if="isSelectCoordinateDialogOpen" persistent>
			<select-site-coordinate-system :site-id="this.siteId" :coordinate-system-pf="this.siteInfoPf"
				:coordinate-system-db="this.siteInfoDb" :coordinate-system-dashboard="this.siteInfoDashboard"
				@close="closeSelectCoordinateDialog" @update="updateLocalCoordinateSystem" />
		</v-dialog>
		<v-dialog v-model="isFileDialogOpen" width="600px" v-if="isFileDialogOpen" persistent>
			<local-file-upload v-bind:project="{ site_id: this.siteId }" @close="closeFileUploadDialog" />
		</v-dialog>
	</v-navigation-drawer>
</template>

<script>
import axios from "axios";
import * as constant from "../../constant";
import LocalFileUpload from "../AddData/LocalFileUpload.vue";
import SelectSiteCoordinateSystem from "../AddData/SelectSiteCoordinateSystem.vue";

export default {
	name: "ListPanel",

	components: {
		LocalFileUpload,
		SelectSiteCoordinateSystem,
	},
	data: () => ({
		sites: [],
		numbers: [5, 10, 15, 20],
		word: "",
		pageCount: 1,
		page: 1,
		limit: 10,
		projects: [
			{
				name: "test",
				siteId: "test",
			},
		],
		isFileDialogOpen: false,
		isSelectCoordinateDialogOpen: false,
		siteInfoPf: null,
		siteInfoDb: null,
		siteInfoDashboard: null,
		siteId: undefined,
	}),
	async mounted() {
		this.getSites();
	},
	watch: {
		sites(newValue, oldValue) {
			// pinを削除
			this.removePins(oldValue);
			// pinを追加
			this.addPins(newValue);
		},
		limit() {
			// 1ページ目に戻して検索実行
			this.getSites(1);
		},
	},
	methods: {
		on_mouseenter(item) {
			window["viewer"].updatePin(item.pinId, {
				color: "#eaaa00",
				highlight: true,
			});
		},
		on_mouseleave(item) {
			window["viewer"].updatePin(item.pinId, {
				color: "#ffffff",
				highlight: false,
			});
		},
		on_click(item) {
			window["viewer"].focusPin(item.pinId);
		},
		getSitesEnter(event) {
			// フォーカスアウトする
			event.target.blur();
			// 検索実行
			this.onSearchChange();
		},
		async gotoProject(site) {
			this.siteId = site.siteId;

			// Google Analyticsに連携
			window["viewer"].focusPin(site.pinId);
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				event: "sc_jobsite_open",
				sc_site_id: this.siteId,
			});

			try {
				this.siteInfoPf = null;
				this.siteInfoDb = null;
				this.siteInfoDashboard = null;

				// get site info from DB
				const response = await axios.get(
					`${import.meta.env.VITE_API_BASE}/sites/${this.siteId}`,
					{
						auth: this.$store.state.authObject,
					},
				);
				this.siteInfoDb = response.data;

				try {
					// get site coordinate system from PF
					const pfLocalizationInfo = await axios.get(
						`${import.meta.env.VITE_API_BASE}/sites/${this.siteId}/coordinate-system`,
						{
							auth: this.$store.state.authObject,
						},
					);
					if (pfLocalizationInfo.status === 200) {
						this.siteInfoPf = pfLocalizationInfo.data;
					} else {
						throw new Error();
					}
				} catch (error) {
					if (error.response?.status === 404) {
						// get site details from dashboard
						try {
							const dashboardLocalizationInfo = await axios.get(
								`${import.meta.env.VITE_API_BASE}/dashboard/${this.siteId}/localization`,
								{
									auth: this.$store.state.authObject,
								},
							);
							this.siteInfoDashboard = dashboardLocalizationInfo.data;
						} catch (error) {
							// Ignore the error because it is treated as the site coordinate system is not registered
						}
					} else {
						throw error;
					}
				}

				// update db params
				const params = {
					epsg: "",
					epsg_v: "",
					rotation: "",
					origin_easting: "",
					origin_northing: "",
					origin_latitude: "",
					origin_longitude: "",
					scale_factor: "",
					vertical_offset: "",
					incline_x: "",
					incline_y: "",
					to_meters: "",
				};

				if (this.siteInfoPf) {
					// site coordinate system exists on PF
					if (this.isNotRegisteredSiteCoordinateSystem(this.siteInfoDb)) {
						// site coordinate system does not exist on DB
						if (
							this.siteInfoPf.based_on ===
							constant.coordinateSystemBasedType.LOCALIZATION
						) {
							// site coordinate system based on "localization"
							params.rotation =
								this.siteInfoPf.localization?.projection.parameters?.rotation_angle;
							params.origin_easting =
								this.siteInfoPf.localization?.projection.parameters?.false_easting;
							params.origin_northing =
								this.siteInfoPf.localization?.projection.parameters?.false_northing;
							params.origin_latitude =
								this.siteInfoPf.localization?.projection.parameters?.latitude_of_origin;
							params.origin_longitude =
								this.siteInfoPf.localization?.projection.parameters?.central_meridian;
							params.scale_factor =
								this.siteInfoPf.localization?.projection.parameters?.scale_factor;
							params.vertical_offset =
								this.siteInfoPf.localization?.projection.parameters?.vertical_offset;
							params.incline_x =
								this.siteInfoPf.localization?.projection.parameters?.inclination_x;
							params.incline_y =
								this.siteInfoPf.localization?.projection.parameters?.inclination_y;
							params.to_meters =
								this.siteInfoPf.localization?.projection.parameters?.unit?.ratio;
							// open the site after update DB
							await this.updateLocalCoordinateSystem(this.siteId, params);
						} else if (
							this.siteInfoPf.based_on ===
							constant.coordinateSystemBasedType.PROJECTION
						) {
							// site coordinate system based on "projection"
							params.epsg = this.siteInfoPf.projection?.srid;
							params.epsg_v = this.siteInfoPf.projection?.vertical_srid;
							// open the site after update DB
							await this.updateLocalCoordinateSystem(this.siteId, params);
						} else {
							// unexpected data
							throw new Error();
						}
					} else if (
						this.siteInfoPf.based_on ===
						constant.coordinateSystemBasedType.LOCALIZATION
					) {
						// check whether coordinate system of PF and DB match
						if (
							String(
								this.siteInfoPf.localization?.projection.parameters
									?.rotation_angle,
							) === this.siteInfoDb.rotation &&
							String(
								this.siteInfoPf.localization?.projection.parameters
									?.false_easting,
							) === this.siteInfoDb.origin_easting &&
							String(
								this.siteInfoPf.localization?.projection.parameters
									?.false_northing,
							) === this.siteInfoDb.origin_northing &&
							String(
								this.siteInfoPf.localization?.projection.parameters
									?.latitude_of_origin,
							) === this.siteInfoDb.origin_latitude &&
							String(
								this.siteInfoPf.localization?.projection.parameters
									?.central_meridian,
							) === this.siteInfoDb.origin_longitude &&
							String(
								this.siteInfoPf.localization?.projection.parameters
									?.scale_factor,
							) === this.siteInfoDb.scale_factor &&
							String(
								this.siteInfoPf.localization?.projection.parameters
									?.vertical_offset,
							) === this.siteInfoDb.vertical_offset &&
							String(
								this.siteInfoPf.localization?.projection.parameters
									?.inclination_x,
							) === this.siteInfoDb.incline_x &&
							String(
								this.siteInfoPf.localization?.projection.parameters
									?.inclination_y,
							) === this.siteInfoDb.incline_y &&
							String(
								this.siteInfoPf.localization?.projection.parameters?.unit
									?.ratio,
							) === this.siteInfoDb.to_meters
						) {
							// match
							this.$router.push(`/?siteId=${this.siteId}`);
						} else {
							// unmatch
							this.isSelectCoordinateDialogOpen = true;
						}
					} else if (
						this.siteInfoPf.based_on ===
						constant.coordinateSystemBasedType.PROJECTION
					) {
						// check whether coordinate system of PF and DB match
						if (
							this.siteInfoPf.projection.srid === this.siteInfoDb.epsg &&
							this.siteInfoPf.projection.vertical_srid ===
								this.siteInfoDb.epsg_v
						) {
							// match
							this.$router.push(`/?siteId=${this.siteId}`);
						} else {
							// unmatch
							this.isSelectCoordinateDialogOpen = true;
						}
					} else {
						// unexpected data
						throw new Error();
					}
				} else if (this.siteInfoDashboard) {
					// coordinate system exists on dashboard
					if (this.isNotRegisteredSiteCoordinateSystem(this.siteInfoDb)) {
						// site coordinate system does not exist on DB
						if (
							this.siteInfoDashboard.type ===
							constant.dashboardLocalizationType.NAMED_SYSTEM
						) {
							const target = this.siteInfoDashboard.namedSystem;
							params.epsg = target.horizontal;
							params.epsg_v = target.vertical;
						} else {
							const target = this.siteInfoDashboard.obliqueStereographic;
							params.rotation = target.rotation;
							params.origin_easting = target.originEast;
							params.origin_northing = target.originNorth;
							params.origin_latitude = target.originLatitude;
							params.origin_longitude = target.originLongitude;
							params.scale_factor = target.scale;
							params.vertical_offset = target.verticalOffset;
							params.incline_x = target.inclineX;
							params.incline_y = target.inclineY;
							params.to_meters = target.toMeters;
						}
						// open the site after update DB
						await this.updateLocalCoordinateSystem(this.siteId, params);
					} else if (
						this.siteInfoDashboard.type ===
						constant.dashboardLocalizationType.NAMED_SYSTEM
					) {
						// check whether coordinate system of dashboard and DB match
						const target = this.siteInfoDashboard.namedSystem;
						if (
							target.horizontal === this.siteInfoDb.epsg &&
							target.vertical === this.siteInfoDb.epsg_v
						) {
							// match
							this.$router.push(`/?siteId=${this.siteId}`);
						} else {
							//unmatch
							this.isSelectCoordinateDialogOpen = true;
						}
					} else {
						// check whether coordinate system of dashboard and DB match
						const target = this.siteInfoDashboard.obliqueStereographic;
						if (
							String(target.rotation) === this.siteInfoDb.rotation &&
							String(target.originEast) === this.siteInfoDb.origin_easting &&
							String(target.originNorth) === this.siteInfoDb.origin_northing &&
							String(target.originLatitude) ===
								this.siteInfoDb.origin_latitude &&
							String(target.originLongitude) ===
								this.siteInfoDb.origin_longitude &&
							String(target.scale) === this.siteInfoDb.scale_factor &&
							String(target.verticalOffset) ===
								this.siteInfoDb.vertical_offset &&
							String(target.inclineX) === this.siteInfoDb.incline_x &&
							String(target.inclineY) === this.siteInfoDb.incline_y &&
							String(target.toMeters) === this.siteInfoDb.to_meters
						) {
							// match
							this.$router.push(`/?siteId=${this.siteId}`);
						} else {
							//unmatch
							this.isSelectCoordinateDialogOpen = true;
						}
					}
				} else {
					// coordinate system does not not exist on PF
					if (this.isNotRegisteredSiteCoordinateSystem(this.siteInfoDb)) {
						// coordinate system does not exist on DB
						this.isFileDialogOpen = true;
					} else {
						// coordinate system exists on DB
						this.$router.push(`/?siteId=${this.siteId}`);
					}
				}
			} catch (error) {
				if (error.message.indexOf("504") > 0) {
					window.location.href = `${import.meta.env.VITE_API_BASE}/login`;
				} else {
					this.$store.commit("set_snackbar", {
						text: this.$t("MESSAGE_ERROR_GET_COORDINATE_SYSTEM"),
						color: "rgba(153, 0, 0, 0.72)",
					});
				}
			}

			//remove all the pins
			this.removePins(this.sites);
		},
		addPins(sites) {
			for (const site of sites) {
				site.pinId = window["viewer"].addPin(
					Number(site.longitude),
					Number(site.latitude),
					"#ffffff",
				);
			}
		},
		removePins(sites) {
			for (const site of sites) {
				window["viewer"].removePin(site.pinId);
			}
		},
		onSearchChange() {
			// 1ページ目に戻して検索実行
			this.getSites(1);
		},
		async setPageCount() {
			const params = {};

			if (this.word) {
				params.siteName = this.word;
			}

			try {
				const response = await axios.get(
					`${import.meta.env.VITE_API_BASE}/sites/count`,
					{
						auth: this.$store.state.authObject,
						params,
					},
				);
				const siteCount = response.data.siteCount;
				// ページ数
				return siteCount % this.limit === 0
					? Math.floor(siteCount / this.limit)
					: Math.floor(siteCount / this.limit) + 1;
			} catch (e) {
				// リクエストに失敗したらページ数を初期値に戻す
				console.error(e.message);
				throw e;
			}
		},
		async getSites(pageNumber) {
			// 引数がある場合は指定されたページのデータを取得
			const page = pageNumber ? pageNumber : this.page;
			const params = {
				offset: page * this.limit - this.limit + 1,
				limit: this.limit,
			};

			if (this.word) {
				params.siteName = this.word;
			}

			try {
				this.$store.dispatch("updateIsLoading", true);
				const pageCount = await this.setPageCount();
				const response = await axios.get(
					`${import.meta.env.VITE_API_BASE}/sites`,
					{
						auth: this.$store.state.authObject,
						params,
					},
				);
				this.sites = response.data.sites;
				// 現場情報取得後にページ数と現在のページ番号を画面に反映
				this.pageCount = pageCount;
				this.page = page;
			} catch (e) {
				console.error(e.message);
			} finally {
				this.$store.dispatch("updateIsLoading", false);
			}
		},
		//here we are sending the uploaded data to the server
		async closeFileUploadDialog(uploadedData) {
			this.isFileDialogOpen = false;
			if (uploadedData) {
				this.$router.push(`/?siteId=${this.siteId}`);
			}
		},
		closeSelectCoordinateDialog() {
			this.isSelectCoordinateDialogOpen = false;
		},
		async updateLocalCoordinateSystem(siteId, coordinateInfo) {
			this.isSelectCoordinateDialogOpen = false;
			if (coordinateInfo) {
				try {
					// Open Spinner
					this.$store.dispatch("updateIsLoading", true);
					// Update DB
					const response = await axios.post(
						`${import.meta.env.VITE_API_BASE}/sites/epsg/${siteId}`,
						coordinateInfo,
						{
							auth: this.$store.state.authObject,
						},
					);
					if (response.status === 200) {
						this.$router.push(`/?siteId=${siteId}`);
					} else {
						throw new Error();
					}
				} catch (error) {
					const errorMessage = this.$t(
						"MESSAGE_ERROR_FAILED_SET_COORDINATE_SYSTEM",
					);
					const status = error.response?.status;
					if (status) {
						this.$store.commit("set_snackbar", {
							text: this.$t("UPDATE_FAILED_EDITING_OR_UPLOADING"),
							color: "rgba(153, 0, 0, 0.72)",
						});
					} else {
						this.$store.commit("set_snackbar", {
							text: errorMessage,
							color: "rgba(153, 0, 0, 0.72)",
						});
					}
				} finally {
					// Close Spinner
					this.$store.dispatch("updateIsLoading", false);
				}
			} else {
				// DBの座標系情報を使用するため更新なしで現場表示
				this.$router.push(`/?siteId=${siteId}`);
			}
		},
		isNotRegisteredSiteCoordinateSystem(siteInfoDb) {
			return (
				!siteInfoDb.epsg &&
				!siteInfoDb.epsg_v &&
				!siteInfoDb.rotation &&
				!siteInfoDb.origin_easting &&
				!siteInfoDb.origin_northing &&
				!siteInfoDb.origin_latitude &&
				!siteInfoDb.origin_longitude &&
				!siteInfoDb.scale_factor &&
				!siteInfoDb.vertical_offset &&
				!siteInfoDb.incline_x &&
				!siteInfoDb.incline_y &&
				!siteInfoDb.to_meters
			);
		},
	},
};
</script>

<style>
.drawer {
	width: 50vw !important;
	border-top: 1px solid #434348;
}

.content-area {
	height: calc(100% - 72px);
}

.search-input {
	width: 70%;
	height: 30px;
}

.header-site-name {
	width: 80%;
}

.v-navigation-drawer__content {
	overflow-y: hidden !important;
}

.project-table {
	max-height: calc(100% - 140px - 48px);
	overflow-y: auto;
}
</style>
