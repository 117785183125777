/** The representing delimiters for date formatting. */
export const DateFormatDelimiter = Object.freeze({
	HYPHEN: "-",
	SLASH: "/",
});

/** The representing delimiters for time formatting. */
export const TimeFormatDelimiter = Object.freeze({
	COLON: ":",
	HYPHEN: "-",
});

/**
 * Converts a Date object to a formatted date string.
 *
 * @param {Date} date - The Date object to be converted.
 * @param {string} delimiter - The delimiter to use between year, month, and day.
 * @param {boolean} [zeroPadding=true] - Whether to pad single-digit months and days with a leading zero. Default is true.
 * @returns {string} The formatted date string.
 */
export const convertToDateString = (date, delimiter, zeroPadding = true) => {
	const year = date.getFullYear().toString();
	const month = zeroPadding
		? (date.getMonth() + 1).toString().padStart(2, "0")
		: (date.getMonth() + 1).toString();
	const day = zeroPadding
		? date.getDate().toString().padStart(2, "0")
		: date.getDate().toString();
	return `${year}${delimiter}${month}${delimiter}${day}`;
};

/**
 * Converts a Date object to a formatted time string.
 *
 * @param {Date} date - The Date object to be converted.
 * @param {string} delimiter - The delimiter to use between hours, minutes, and seconds.
 * @param {boolean} [zeroPadding=true] - Whether to pad single-digit hours, minutes, and seconds with a leading zero. Default is true.
 * @returns {string} The formatted time string.
 */
export const convertToTimeString = (date, delimiter, zeroPadding = true) => {
	const hours = zeroPadding
		? date.getHours().toString().padStart(2, "0")
		: date.getHours().toString();
	const minutes = zeroPadding
		? date.getMinutes().toString().padStart(2, "0")
		: date.getMinutes().toString();
	const seconds = zeroPadding
		? date.getSeconds().toString().padStart(2, "0")
		: date.getSeconds().toString();
	return `${hours}${delimiter}${minutes}${delimiter}${seconds}`;
};
