export const UnitTypes = [
	{
		label: "Percent",
		value: "percent",
		symbol: "%",
	},
	{
		label: "RATIO_WORD",
		value: "ratio",
		symbol: "1/n",
	},
	{
		label: "Degree",
		value: "degree",
		symbol: "°",
	},
];

export const pointCloudProcessingStatus = Object.freeze({
	ERROR: ["SERVER_ERROR", "SST_ERROR"],
	PROCESSING: ["SERVER", "SST"],
	FINISHED: ["SST_FINISHED"],
});

export const designProcessingStatus = Object.freeze({
	ERROR: ["error"],
	PROCESSING: ["upload", "complete_dxfparse", "complete_server"],
	FINISHED: ["complete", "complete_wgs84"],
});

export const chartBoardTabs = Object.freeze({
	PROFILE: {
		id: "profile",
		title: "Longitudinalsectionview",
		component: "ProfileViewDialog",
	},
	SECTION: {
		id: "section",
		title: "Crosssectionview",
		component: "SectionViewDialog",
	},
	LINE_PROFILE: {
		id: "lineProfile",
		title: "LINE_PROFILE_VIEW",
		component: "LineProfileViewDialog",
	},
	LINE_SECTION: {
		id: "lineSection",
		title: "Linesectionview",
		component: "LineSectionViewDialog",
	},
	MEASUREMENT: {
		id: "measurement",
		title: "Measurementview",
		component: "MeasurementViewDialog",
	},
});

/**
 * オブジェクトタイプ
 * 0: 道路
 * 1: 平場
 * 2: ラインワークス
 * 7: 床掘
 */
export const objectType = Object.freeze({
	ROAD: 0,
	FLAT: 1,
	LINE_WORKS: 2,
	TRENCH: 7,
});

export const LongitudinalSlopeType = Object.freeze({
	FLAT: "flat",
	CONSTANT: "constant",
	DEFAULT: "default",
	NONE: "none",
});

export const ReferencePoint = Object.freeze({
	FIRST: "first",
	LAST: "last",
});

export const RubbingDestination = Object.freeze({
	POINT_CLOUD: 0,
	DESIGN_DATA: 1,
	FLAT_GROUND: 2,
	SPECIFIED_ALTITUDE: 3,
	REFERENCE_TOPLOGY: 4,
});

/**
 * 仮設道路の区間の線のタイプ
 * curve: 曲線
 * straight: 直線
 */
export const segmentType = Object.freeze({
	CURVE: "curve",
	STRAIGHT: "straight",
});

/**
 * The type of which the coordinate system is based
 */
export const coordinateSystemBasedType = Object.freeze({
	LOCALIZATION: "localization",
	PROJECTION: "projection",
});

/**
 * The type of which is registered to Dashboard project
 */
export const dashboardLocalizationType = Object.freeze({
	NAMED_SYSTEM: "NAMED_SYSTEM",
	OBLIQUE_STEREOGRAPHIC: "OBLIQUE_STEREOGRAPHIC",
});
