<template>
    <div>
        <v-card-text class="py-0">
            <v-btn
              size="36"
              variant="text"
              icon
              @click="toggleExpansion"
              class="ml-0"
            >
                <v-icon>
                    mdi-play  {{ expansion ? "mdi-rotate-90" : "" }}
                </v-icon>
            </v-btn>
            <span>{{ getTitle() }}</span>
        </v-card-text>
        <v-expand-transition>
          <div
            v-show="expansion"
            class="mx-3 mb-3"
          >
              <v-card-text v-if="objects.length == 0" class="pa-0 pl-10">
                  {{ $t("NO_DATA") }}
              </v-card-text>
              <object-group
                v-for="group in objectGroups"
                :key="group.pointCloud.getId()"
                :group = group
                :isRoadAdd="isRoadAdd"
                :isFlatAdd="isFlatAdd"
                :isTrenchAdd="isTrenchAdd"
                :globeVisibilityBeforeCreationMode="globeVisibilityBeforeCreationMode"
                :objects2DViewVisibility="objects2DViewVisibility"
                @updateObject2DViewVisibility="updateObject2DViewVisibility"
                @updateGlobeVisibility="updateGlobeVisibility"
                @clear2DViews="clear2DViews"
                class="pa-0 ml-3"
              >
              </object-group>
          </div>
        </v-expand-transition>
    </div>
</template>

<script>
import ObjectGroup from "./ObjectGroup.vue";
import { mapState } from "vuex";
import * as constants from "@/constant.js";
import objUpdateMixin from "@/mixins/objUpdateMixin.js";

export default {
	mixins: [objUpdateMixin],
	components: {
		ObjectGroup,
	},
	props: {
		objId: String,
		isFlatAdd: Boolean,
		isRoadAdd: Boolean,
		isTrenchAdd: Boolean,
		globeVisibilityBeforeCreationMode: Boolean,
		updateObjectListOfCreation: Boolean,
	},
	data() {
		return {
			expansion: false,
			objects2DViewVisibility: {},
		};
	},
	computed: {
		...mapState(["objects"]),
		//     return this.$store.getters.objects
		//   },
		objectGroups() {
			const objectGroups = this.$store.getters.objects.reduce(
				(groups, object) => {
					if (!object.json) return groups;
					let assetId;
					if (
						object.type === constants.objectType.TRENCH &&
						(object.settings.commonSettings.destination.target ===
							constants.RubbingDestination.POINT_CLOUD ||
							object.settings.commonSettings.destination.target ===
								constants.RubbingDestination.DESIGN_DATA)
					) {
						assetId = object.settings.commonSettings.destination.id;
					}
					if (!assetId) {
						assetId = JSON.parse(object.json).associatePointCloudId;
					}
					if (!assetId) {
						assetId = JSON.parse(object.json).associateDrawing2dImageId;
					}
					if (!assetId) return groups;
					assetId = assetId.toString();
					let group = groups.get(assetId);
					if (!group) {
						group = [];
						groups.set(assetId, group);
					}
					group.push(object);
					return groups;
				},
				new Map(),
			);
			return this.$store.getters.pointCloudAndDesignList.reduce(
				(groups, pointCloud) => {
					const objects = objectGroups.get(pointCloud.asset_id?.toString());
					if (objects) {
						groups.push({ pointCloud, objects });
					}
					return groups;
				},
				[],
			);
		},
	},
	watch: {
		objects(objs) {
			objs.forEach((obj) => {
				if (this.objects2DViewVisibility[obj.id] === undefined) {
					this.objects2DViewVisibility[obj.id] = false;
				}
			});
		},
		updateObjectListOfCreation() {
			this.clear2DViews();
		},
	},
	methods: {
		getTitle() {
			return `${this.$t("OBJECT_DATA")}`;
		},
		toggleExpansion() {
			this.expansion = !this.expansion;
		},
		updateGlobeVisibility(visibility) {
			this.$emit("updateGlobeVisibility", visibility);
		},
		updateObject2DViewVisibility(show2DView, id) {
			if (!show2DView) {
				window["viewer"].clear2DViewes();
			}
			this.objects2DViewVisibility[id] = show2DView;
		},
		clear2DViews() {
			window["viewer"].clear2DViewes();
			Object.keys(this.objects2DViewVisibility).forEach((k) => {
				this.objects2DViewVisibility[k] = false;
			});
		},
	},
};
</script>