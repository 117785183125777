<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title> {{ $t("Addtemporarytrench") }} </v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-row class="ma-0">
          <v-col cols="6">
            <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
              <tbody>
                <tr>
                  <td style="width: 50%">
                    {{ $t("Trenchname") }}
                  </td>
                  <td>
                    <v-text-field variant="underlined" hide-details density="compact" v-model="property.name"
                      :rules="[rules.validName, rules.maxLength(100), rules.minLength(1)]" autofocus />
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col>
            <v-row class="ma-0">
              <v-col class="py-0">
                {{ $t("Trenchparameter") }}
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="6">
                <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
                  <tbody>
                    <tr>
                      <td style="width: 50%">{{ $t("Widthoftrench") }}</td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                          step="0.001" suffix="m" @change="updateTrenchSettings()"
                          v-model.number="property.settings.commonSettings.width"
                          :rules="[rules.orLess(9999.999), rules.orMore(0.001), rules.decimalLength(3), rules.numberFormat]" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("InterpolationPitch") }}
                      </td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                          step="0.001" suffix="m" @change="updateTrenchSettings()"
                          v-model.number="property.settings.commonSettings.interpolationPitch"
                          :rules="[rules.required, rules.orLess(9999.999), rules.orMore(0.001), rules.decimalLength(3), rules.numberFormat]" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("Trenchsurfacecolor") }}
                      </td>
                      <td>
                        <v-menu>
                          <template v-slot:activator="{ props }">
                            <v-avatar :color="property.settings.commonSettings.surfaceColor" size="20px" v-bind="props">
                            </v-avatar>
                          </template>
                          <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                            v-model="property.settings.commonSettings.surfaceColor"
                            @update:model-value="updateTrenchMaterial()"></v-color-picker>
                        </v-menu>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("RUBBLING_DESTINATION") }}
                      </td>
                      <td>
                        <v-select variant="underlined" v-model="property.settings.commonSettings.destination.target"
                          :items="rubbingDestinations" item-title="label" item-value="value" hide-details
                          density="compact" class="ma-0"></v-select>
                      </td>
                    </tr>
                    <tr v-if="property.settings.commonSettings.destination.target === 0">
                      <td>{{ $t("REFERENCE_POINT_CLOUD_ASSET") }}</td>
                      <td>
                        <v-select variant="underlined" v-model="property.settings.commonSettings.destination.id"
                          :items="pointClouds" :item-title="(item) => item && item.getName()"
                          :item-value="(item) => item && item.getAssetIdNum()" density="compact" hide-details
                          class="ma-0">
                        </v-select>
                      </td>
                    </tr>
                    <tr v-if="property.settings.commonSettings.destination.target === 1">
                      <td>{{ $t("REFERENCE_DESIGN_ASSET") }}</td>
                      <td>
                        <v-select variant="underlined" v-model="property.settings.commonSettings.destination.id"
                          :items="designs" :item-title="(item) => item && item.getName()"
                          :item-value="(item) => item && item.getAssetIdNum()" density="compact" hide-details
                          class="ma-0" :rules="[rules.required]">
                        </v-select>
                      </td>
                    </tr>
                    <tr v-if="property.settings.commonSettings.destination.target === 3">
                      <td>{{ $t("SPECIFIED_ALTITUDE") }}</td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="-9999.999"
                          max="9999.999" step="0.001" suffix="m" @change="updateTrenchSettings()"
                          v-model.number="property.settings.commonSettings.destination.specifiedAltitude"
                          :rules="[rules.required0, rules.orMore(-9999.999), rules.orLess(9999.999), rules.decimalLength(3), rules.numberFormat]" />
                      </td>
                    </tr>
                    <tr v-if="property.settings.commonSettings.destination.target === 2">
                      <td>{{ $t("REFERENCE_FLAT_GROUND") }}</td>
                      <td>
                        <v-select variant="underlined" v-model="property.settings.commonSettings.destination.id"
                          :items="flatGrounds" :item-title="(item) => item.name" :item-value="(item) => item.id"
                          density="compact" hide-details class="ma-0" :rules="[rules.required]">
                        </v-select>
                      </td>
                    </tr>
                    <tr v-if="property.settings.commonSettings.destination.target === 4">
                      <td>{{ $t("REFERENCE_TOPOLOGY") }}</td>
                      <td>
                        <v-select variant="underlined" v-model="property.settings.commonSettings.destination.id"
                          :items="drawing2dImages" :item-title="(item) => item.name"
                          :item-value="(item) => item.asset_id" density="compact" hide-details class="ma-0"
                          :rules="[rules.required]">
                        </v-select>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>
              <v-col class="col-6">
                <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
                  <tbody>
                    <tr>
                      <td style="width: 110px">
                        {{ $t("Longitudinal_Slope_Type") }}
                      </td>
                      <td>
                        <v-select variant="underlined"
                          v-model="property.settings.commonSettings.longitudinalSlope.slopeType"
                          :items="longitudinalSlopeType" item-title="label" item-value="value" hide-details
                          density="compact" class="ma-0" @update:model-value="updateTrenchSettings()"></v-select>
                      </td>
                    </tr>
                    <tr v-if="enableSpecifiedElevation">
                      <td class="text-pre-wrap">
                        <span v-text="$t('Specified_Elevation')"></span>
                      </td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="-9999.999"
                          max="9999.999" step="0.001" suffix="m" @change="updateTrenchSettings()"
                          v-model.number="property.settings.commonSettings.specifiedElevation"
                          :rules="[rules.required0, rules.orMore(-9999.999), rules.orLess(9999.999), rules.decimalLength(3), rules.numberFormat]" />
                        <span style="
                             {
                              word-wrap: break-all;
                              font-size: small;
                              opacity: 0.6;
                            }
                          ">
                          <i>{{ `${$t("DEFAULT_ELEVATION")}：${elevation}` }}</i>
                        </span>
                      </td>
                    </tr>
                    <tr v-if="enableSlopeValue">
                      <td>
                        {{ $t("Longitudinal_Slope_Value") }}
                      </td>
                      <td>
                        <SlopeSettingInput :suffix-to-display="getSuffix(trenchLongitudinalSlopeUnit)"
                          :is-editable="true"
                          :slope-value="property.settings.commonSettings.longitudinalSlope.slopeValue" :step="0.001"
                          @update:model-value="updateTrenchSettings"
                          v-model.number="property.settings.commonSettings.longitudinalSlope.slopeValue"
                          :max-value="trenchLongitudinalSlopeUnit === 'percent' ? 9999.999 : 999.999"
                          :min-value="trenchLongitudinalSlopeUnit === 'percent' ? -100 : 0.001" :decimal-length="3"
                          :un-valid-zero="trenchLongitudinalSlopeUnit === 'percent'"></SlopeSettingInput>
                      </td>
                    </tr>
                    <tr v-if="enableDepth">
                      <td>
                        {{ $t("TRENCH_DEPTH") }}
                      </td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" max="9999.999"
                          min="0.001" step="0.001" suffix="m" @change="updateTrenchSettings()"
                          v-model.number="property.settings.commonSettings.longitudinalSlope.depth"
                          :rules="[rules.orLess(9999.999), rules.orMore(0.001), rules.decimalLength(3), rules.numberFormat]" />
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col>
            <v-row class="ma-0">
              <v-col class="py-0">
                {{ $t("Cutsetting") }}
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col>
                <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
                  <tbody>
                    <tr>
                      <td style="width: 50%">
                        {{ $t("Slopeheight") }}
                      </td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                          step="0.01" suffix="m"
                          :rules="cutSlopeCondition ? [] : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                          :disabled="cutSlopeCondition" @update:model-value="updateTrenchSettings()"
                          v-model.number="property.settings.cutSettings.trenchHeight" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("Slopegradient") }}
                      </td>
                      <td>
                        <SlopeSettingInput :suffix-to-display="getSuffix(trenchCutSettingSlopeUnit.slopeGradient)"
                          :is-editable="!property.settings.cutSettings.bLeftRightSettings"
                          :slope-value="property.settings.cutSettings.moundSlope" :step="0.01"
                          @update:model-value="updateTrenchSettings"
                          v-model.number="property.settings.cutSettings.moundSlope"
                          :max-value="trenchCutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                          :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("Bermwidth") }}
                      </td>
                      <td>
                        <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                          step="0.01" suffix="m" :disabled="cutSlopeCondition"
                          :rules="cutSlopeCondition ? [] : [rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                          @update:model-value="updateTrenchSettings()"
                          v-model.number="property.settings.cutSettings.shelfWidth" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("Bermgradient") }}
                      </td>
                      <td>
                        <SlopeSettingInput :suffix-to-display="getSuffix(trenchCutSettingSlopeUnit.bermGradient)"
                          :is-editable="!cutSlopeCondition" :slope-value="property.settings.cutSettings.shelfSlope"
                          :step="0.01" @update:model-value="updateTrenchSettings"
                          v-model.number="property.settings.cutSettings.shelfSlope"
                          :max-value="trenchCutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                          :min-value="trenchCutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                          :decimal-length="2" :un-valid-zero="trenchCutSettingSlopeUnit.bermGradient === 'percent'">
                        </SlopeSettingInput>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("LeftRightSettings") }}
                      </td>
                      <td>
                        <v-checkbox color="primary" hide-details density="compact" class="ma-0"
                          @click="updateTrenchSettings()"
                          v-model="property.settings.cutSettings.bLeftRightSettings"></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("Thereisberm") }}
                      </td>
                      <td>
                        <v-checkbox color="primary" hide-details density="compact" class="ma-0"
                          @click="updateTrenchSettings()"
                          v-model="property.settings.cutSettings.generateShelf"></v-checkbox>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ $t("Trenchcolor") }}
                      </td>
                      <td>
                        <v-menu>
                          <template v-slot:activator="{ props }">
                            <v-avatar :color="property.settings.cutSettings.color" size="20px" v-bind="props">
                            </v-avatar>
                          </template>
                          <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                            v-model="property.settings.cutSettings.color"
                            @update:model-value="updateTrenchMaterial()"></v-color-picker>
                        </v-menu>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
                <v-expansion-panels>
                  <v-expansion-panel style="background-color: rgba(41, 41, 47, 0)">
                    <v-expansion-panel-title class="px-4 py-0">
                      {{ $t("Leftrightsetting") }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text class="pt-2">
                      <v-row class="ma-0">
                        <v-col class="pa-0">
                          {{ $t("Leftcutsetting") }}
                          <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)" class="pa-0">
                            <tbody>
                              <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number"
                                    min="0" step="0.01" suffix="m" :disabled="cutLeftRightCondition"
                                    :rules="cutLeftRightCondition ? [] : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                                    @update:model-value="updateTrenchSettings()"
                                    v-model.number="property.settings.cutSettings.leftSlopeHeight" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>
                                  <SlopeSettingInput
                                    :suffix-to-display="getSuffix(trenchCutSettingSlopeUnit.slopeGradient)"
                                    :is-editable="property.settings.cutSettings.bLeftRightSettings"
                                    :slope-value="property.settings.cutSettings.leftMoundSlope" :step="0.01"
                                    @update:model-value="updateTrenchSettings"
                                    v-model.number="property.settings.cutSettings.leftMoundSlope"
                                    :max-value="trenchCutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number"
                                    min="0" step="0.01" suffix="m" :disabled="cutLeftRightCondition"
                                    :rules="cutLeftRightCondition ? [] : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                                    @update:model-value="updateTrenchSettings()"
                                    v-model.number="property.settings.cutSettings.leftShelfWidth" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>
                                  <SlopeSettingInput
                                    :suffix-to-display="getSuffix(trenchCutSettingSlopeUnit.bermGradient)"
                                    :is-editable="!cutLeftRightCondition"
                                    :slope-value="property.settings.cutSettings.leftShelfSlope" :step="0.01"
                                    @update:model-value="updateTrenchSettings"
                                    v-model.number="property.settings.cutSettings.leftShelfSlope"
                                    :max-value="trenchCutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="trenchCutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                    :decimal-length="2"
                                    :un-valid-zero="trenchCutSettingSlopeUnit.bermGradient === 'percent'">
                                  </SlopeSettingInput>
                                </td>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                        <v-col class="pa-0">
                          {{ $t("Rightcutsetting") }}
                          <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
                            <tbody>
                              <tr>
                                <td style="width: 50%">
                                  {{ $t("Slopeheight") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number"
                                    min="0" step="0.01" suffix="m" :disabled="cutLeftRightCondition"
                                    :rules="cutLeftRightCondition ? [] : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                                    @update:model-value="updateTrenchSettings()"
                                    v-model.number="property.settings.cutSettings.rightSlopeHeight" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("Slopegradient") }}
                                </td>
                                <td>
                                  <SlopeSettingInput
                                    :suffix-to-display="getSuffix(trenchCutSettingSlopeUnit.slopeGradient)"
                                    :is-editable="property.settings.cutSettings.bLeftRightSettings"
                                    :slope-value="property.settings.cutSettings.rightMoundSlope" :step="0.01"
                                    @update:model-value="updateTrenchSettings"
                                    v-model.number="property.settings.cutSettings.rightMoundSlope"
                                    :max-value="trenchCutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("Bermwidth") }}
                                </td>
                                <td>
                                  <v-text-field variant="underlined" hide-details density="compact" type="number"
                                    min="0" step="0.01" suffix="m" :disabled="cutLeftRightCondition"
                                    :rules="cutLeftRightCondition ? [] : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                                    @update:model-value="updateTrenchSettings()"
                                    v-model.number="property.settings.cutSettings.rightShelfWidth" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ $t("Bermgradient") }}
                                </td>
                                <td>
                                  <SlopeSettingInput
                                    :suffix-to-display="getSuffix(trenchCutSettingSlopeUnit.bermGradient)"
                                    :is-editable="!cutLeftRightCondition"
                                    :slope-value="property.settings.cutSettings.rightShelfSlope" :step="0.01"
                                    @update:model-value="updateTrenchSettings"
                                    v-model.number="property.settings.cutSettings.rightShelfSlope"
                                    :max-value="trenchCutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                    :min-value="trenchCutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                    :decimal-length="2"
                                    :un-valid-zero="trenchCutSettingSlopeUnit.bermGradient === 'percent'">
                                  </SlopeSettingInput>
                                </td>
                              </tr>
                            </tbody>
                          </v-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col>
            <v-card-actions class="justify-end">
              <v-btn variant="flat" @click="closeDialog" color="red" :disabled="isTrenchAdded">
                {{ $t("CANCEL") }}
              </v-btn>
              <v-btn variant="flat" @click="addTrench()" color="primary"
                :disabled="!isValid || addTrenchDisabled || isTrenchAdded">
                {{ $t("ADD") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import SlopeSettingInput from "./SlopeSettingInput.vue";
import {
	LongitudinalSlopeType,
	UnitTypes,
	RubbingDestination,
	objectType,
} from "../../constant";
import rules from "@/utils/validation-rules.js";
import { updateTrenchSettingsInViewer } from "./../../utils/cesium-common";

export default {
	name: "PropertyDialogTrench",
	components: { SlopeSettingInput },
	props: {
		id: String,
		type: Number,
	},
	beforeMount() {
		this.property = JSON.parse(
			JSON.stringify(this.$store.getters.defaultTrenchProperty),
		);
		this.setActivePointCloudId();
	},
	async mounted() {
		this.setInitialDestination();
		await this.setElevation();
		this.initialize();
	},
	data: () => ({
		isValid: false,
		property: null,
		isTrenchAdded: false,
		elevation: "",
		rules: {
			...rules,
			required: (value) => !!value || "*",
			required0: (value) => value === 0 || !!value || "*",
			validName: (v) => !/[~@#$^*()+=[\]{}|\\,?:<>!'"/;&₹€`%]/.test(v),
		},
	}),
	computed: {
		designs() {
			return this.$store.state.design_list.filter(
				(item) => item.getIsAssetUploaded() === "DONE",
			);
		},
		pointClouds() {
			return this.$store.state.point_cloud_list.filter(
				(item) => item.getIsAssetUploaded() === "DONE",
			);
		},
		flatGrounds() {
			return this.$store.state.objects.filter(
				(item) => item.type === objectType.FLAT,
			);
		},
		drawing2dImages() {
			return this.$store.getters["drawing2dImage/getDrawing2dImageList"].filter(
				(drawing2dImage) => drawing2dImage.getIsVisible(),
			);
		},
		// Disabling condition for cut setting's - shelfSlope, shelfWidth
		cutSlopeCondition() {
			return (
				this.property.settings.cutSettings.bLeftRightSettings ||
				!this.property.settings.cutSettings.generateShelf
			);
		},
		// Disabling condition for cut setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
		cutLeftRightCondition() {
			return !(
				this.property.settings.cutSettings.bLeftRightSettings &&
				this.property.settings.cutSettings.generateShelf
			);
		},
		trenchParameterSlopeUnit() {
			return this.$store.state.trenchParameterSlopeUnit;
		},
		trenchCutSettingSlopeUnit() {
			return this.$store.state.trenchCutSettingSlopeUnit;
		},
		trenchLongitudinalSlopeUnit() {
			return this.$store.state.trenchLongitudinalSlopeUnit;
		},
		fillSettingSlopeUnit() {
			return this.$store.state.fillSettingSlopeUnit;
		},
		addTrenchDisabled: function () {
			return (
				this.property.name === null ||
				this.property.name === "" ||
				/^\s*$/.test(this.property.name)
			);
		},
		enableSlopeValue() {
			return this.isSlopeType("constant");
		},
		enableSpecifiedElevation() {
			return (
				this.isSlopeType("flat") ||
				this.isSlopeType("constant") ||
				this.isSlopeType("default")
			);
		},
		enableDepth() {
			return this.isSlopeType("none");
		},
		rubbingDestinations() {
			return [
				{
					label: this.$t("POINT_CLOUD"),
					value: RubbingDestination.POINT_CLOUD,
				},
				{
					label: this.$t("DESIGN"),
					value: RubbingDestination.DESIGN_DATA,
				},
				{
					label: this.$t("SPECIFIED_ALTITUDE"),
					value: RubbingDestination.SPECIFIED_ALTITUDE,
				},
				{
					label: this.$t("FLAT_GROUND"),
					value: RubbingDestination.FLAT_GROUND,
				},
				{
					label: this.$t("REFERENCE_TOPOLOGY"),
					value: RubbingDestination.REFERENCE_TOPLOGY,
				},
			];
		},
		longitudinalSlopeType() {
			return [
				{
					label: this.$t("LS_DEFAULT"),
					value: LongitudinalSlopeType.DEFAULT,
				},
				{
					label: this.$t("LS_FLAT"),
					value: LongitudinalSlopeType.FLAT,
				},
				{
					label: this.$t("LS_CONSTANT"),
					value: LongitudinalSlopeType.CONSTANT,
				},
				{
					label: this.$t("LS_NONE"),
					value: LongitudinalSlopeType.NONE,
				},
			];
		},
	},
	watch: {
		"property.settings.commonSettings.destination.target"() {
			const target = this.property.settings.commonSettings.destination.target;
			if (target === RubbingDestination.SPECIFIED_ALTITUDE) {
				this.setDefaultSpecifiedAltitude();
			} else if (
				target === RubbingDestination.POINT_CLOUD ||
				target === RubbingDestination.DESIGN_DATA ||
				target === RubbingDestination.FLAT_GROUND
			) {
				this.setInitialDestination();
			}
		},
		"property.settings.commonSettings.longitudinalSlope.slopeType"() {
			// 床掘下面標高を使用する場合
			if (this.enableSpecifiedElevation) {
				// 変更前が始点の床掘下面標高を使用しない勾配だった場合
				if (this.property.settings.commonSettings.specifiedElevation === null) {
					// デフォルト値を設定
					this.setSpecifiedElevation();
				}
			} else {
				// 未入力にする
				this.property.settings.commonSettings.specifiedElevation = null;
			}

			// 勾配値を設定不可能な場合
			if (!this.enableSlopeValue) {
				// 初期値にする
				this.property.settings.commonSettings.longitudinalSlope.slopeValue = 0;
			}

			// 床掘高さを設定可能な場合
			if (this.enableDepth) {
				// 変更前が床掘高さを設定しない勾配だった場合
				if (
					this.property.settings.commonSettings.longitudinalSlope.depth === null
				) {
					// デフォルト値を設定
					this.property.settings.commonSettings.longitudinalSlope.depth = 1;
				}
			} else {
				// 未入力にする
				this.property.settings.commonSettings.longitudinalSlope.depth = null;
			}
		},
	},
	methods: {
		initialize() {
			this.setSpecifiedElevation();
		},
		setSpecifiedElevation() {
			const specifiedElevation = this.elevation - 1;
			this.property.settings.commonSettings.specifiedElevation =
				+specifiedElevation.toFixed(3);
		},
		resetProperty() {
			this.property = JSON.parse(
				JSON.stringify(this.$store.getters.defaultTrenchProperty),
			);
			this.setActivePointCloudId();
			// this.convertRatioSlopesToPercentSlopes();
			this.isTrenchAdded = false;
		},
		getSuffix(unitType) {
			const unit = UnitTypes.find((type) => type.value === unitType);
			return unit.symbol;
		},
		closeDialog() {
			window["viewer"].deleteTrench(this.id);
			this.$emit("close", false);
			this.property = JSON.parse(
				JSON.stringify(this.$store.getters.defaultTrenchProperty),
			);
			this.setActivePointCloudId();
		},
		setActivePointCloudId() {
			console.log(this.$store.getters.activePointCloudId);
			this.property.settings.commonSettings.destination.id =
				this.$store.getters.activePointCloudId;
		},
		async addTrench() {
			this.isTrenchAdded = true;

			this.property.id = this.id;
			this.property.cid = this.id;
			this.property.type = this.type;
			// const d = JSON.parse(JSON.stringify(this.property.settings));
			try {
				this.$store.dispatch("updateIsLoading", true);
				await updateTrenchSettingsInViewer(this.id, this.property);
				const trenchJson = window["viewer"].getTrenchJSON(this.id);
				const objConnectionJSON = window["viewer"].getConnectionsInSiteJSON();
				this.property.info.length = window["viewer"].getLengthOfTrench(this.id);
				this.property.info.height = window["viewer"].getTrenchHeightInfo(
					this.id,
				);
				this.property.info.slope = window["viewer"].getTrenchSlopeInfo(this.id);

				console.log({
					siteId: this.$route.query.siteId,
					objJSON: trenchJson,
					objProps: this.property,
					objConnectionJSON: objConnectionJSON,
				});
				const res = await axios.post(
					`${import.meta.env.VITE_API_BASE}/obj/${this.$route.query.siteId}`,
					{
						siteId: this.$route.query.siteId,
						objJSON: trenchJson,
						objProps: this.property,
						objConnectionJSON: objConnectionJSON,
					},
					{
						auth: this.$store.state.authObject,
					},
				);
				await this.$store.dispatch("get_obj", res.data.json_name);
			} catch (e) {
				console.error(e);
				window["viewer"].deleteTrench(this.id);
				this.$store.commit("set_snackbar", {
					text: this.$t("TRENCH_SAVE_FAILURE"),
					color: "rgba(153, 0, 0, 0.72)",
				});
			} finally {
				this.$store.dispatch("updateIsLoading", false);
				const addedObj = this.$store.state.objects.find(
					(entity) => entity.cid === this.id,
				);
				if (addedObj) {
					addedObj.isLoaded = true;
					addedObj.isShow = true;
				}
				this.$emit("close", false);
			}
		},
		isNum(val) {
			return typeof val == "number";
		},
		setInitialDestination() {
			if (
				this.property.settings.commonSettings.destination.target ===
				RubbingDestination.POINT_CLOUD
			) {
				// 点群の場合はアクティブなものを設定
				this.property.settings.commonSettings.destination.id =
					this.$store.getters.activePointCloud?.getAssetIdNum() || "";
			} else if (
				this.property.settings.commonSettings.destination.target ===
				RubbingDestination.DESIGN_DATA
			) {
				// 設計データリストを作成日時の降順に並べる
				const designList = this.designs.slice();
				designList.sort(
					(a, b) => new Date(b.getCreatedAt()) - new Date(a.getCreatedAt()),
				);
				if (designList.length) {
					this.property.settings.commonSettings.destination.id =
						designList[0].getAssetIdNum();
				} else {
					this.property.settings.commonSettings.destination.id = "";
				}
			} else if (
				this.property.settings.commonSettings.destination.target ===
				RubbingDestination.FLAT_GROUND
			) {
				if (this.flatGrounds.length) {
					// 平場があれば作成日時最新の平場を選択済みにする
					this.property.settings.commonSettings.destination.id =
						this.flatGrounds[this.flatGrounds.length - 1].id;
				} else {
					this.property.settings.commonSettings.destination.id = "";
				}
			}
		},
		async setElevation() {
			// const ctrPointId = window['viewer'].getTrenchPointIDFromIndex(this.id, 0);
			// const coords =  window['viewer'].getCtrlPointCordiOnTrench(this.id, ctrPointId)
			// const convertedCoords = await convertCoordinateToPointCloudCrs(coords);
			// this.elevation = convertedCoords?.z.toFixed(3) ?? 0;
			this.elevation = window["viewer"].getReferencePointElevationOfTrench(
				this.id,
			);
		},
		isSlopeType(slopeType) {
			return (
				this.property.settings.commonSettings.longitudinalSlope.slopeType ===
				slopeType
			);
		},
		async updateTrenchSettings() {
			if (this.id === null) return;

			// this.updateSlopes(this.property),
			//common settings
			this.property.settings.commonSettings.width = this.isNum(
				this.property.settings.commonSettings.width,
			)
				? this.property.settings.commonSettings.width
				: 1.5;
			this.property.settings.commonSettings.leftSlope = this.isNum(
				this.property.settings.commonSettings.leftSlope,
			)
				? this.property.settings.commonSettings.leftSlope
				: 50;
			this.property.settings.commonSettings.rightSlope =
				this.property.settings.commonSettings.leftSlope;
			this.property.settings.commonSettings.interpolationPitch =
				this.isNum(this.property.settings.commonSettings.interpolationPitch) &&
				this.property.settings.commonSettings.interpolationPitch != 0
					? this.property.settings.commonSettings.interpolationPitch
					: 5;

			this.property.settings.commonSettings.rightSlope = this.property.settings
				.commonSettings.isSuperElevation
				? -1 * this.property.settings.commonSettings.rightSlope
				: this.property.settings.commonSettings.rightSlope;

			this.property.settings.commonSettings.longitudinalSlope.slopeType =
				this.property.settings.commonSettings.longitudinalSlope.slopeType ||
				LongitudinalSlopeType.DEFAULT;

			//cut settings
			this.property.settings.cutSettings.trenchHeight = this.isNum(
				this.property.settings.cutSettings.trenchHeight,
			)
				? this.property.settings.cutSettings.trenchHeight
				: 5;
			this.property.settings.cutSettings.moundSlope = this.isNum(
				this.property.settings.cutSettings.moundSlope,
			)
				? this.property.settings.cutSettings.moundSlope
				: 0.1;

			this.property.settings.cutSettings.shelfWidth = this.isNum(
				this.property.settings.cutSettings.shelfWidth,
			)
				? this.property.settings.cutSettings.shelfWidth
				: 1.5;
			this.property.settings.cutSettings.shelfSlope = this.isNum(
				this.property.settings.cutSettings.shelfSlope,
			)
				? this.property.settings.cutSettings.shelfSlope
				: 100;

			this.property.settings.cutSettings.leftSlopeHeight = this.isNum(
				this.property.settings.cutSettings.leftSlopeHeight,
			)
				? this.property.settings.cutSettings.leftSlopeHeight
				: 5;
			this.property.settings.cutSettings.leftMoundSlope = this.isNum(
				this.property.settings.cutSettings.leftMoundSlope,
			)
				? this.property.settings.cutSettings.leftMoundSlope
				: 0.1;
			this.property.settings.cutSettings.rightSlopeHeight = this.isNum(
				this.property.settings.cutSettings.rightSlopeHeight,
			)
				? this.property.settings.cutSettings.rightSlopeHeight
				: 5;
			this.property.settings.cutSettings.rightMoundSlope = this.isNum(
				this.property.settings.cutSettings.rightMoundSlope,
			)
				? this.property.settings.cutSettings.rightMoundSlope
				: 0.1;

			this.property.settings.cutSettings.leftShelfWidth = this.isNum(
				this.property.settings.cutSettings.leftShelfWidth,
			)
				? this.property.settings.cutSettings.leftShelfWidth
				: 1.5;
			this.property.settings.cutSettings.leftShelfSlope = this.isNum(
				this.property.settings.cutSettings.leftShelfSlope,
			)
				? this.property.settings.cutSettings.leftShelfSlope
				: 100;
			this.property.settings.cutSettings.rightShelfWidth = this.isNum(
				this.property.settings.cutSettings.rightShelfWidth,
			)
				? this.property.settings.cutSettings.rightShelfWidth
				: 1.5;
			this.property.settings.cutSettings.rightShelfSlope = this.isNum(
				this.property.settings.cutSettings.rightShelfSlope,
			)
				? this.property.settings.cutSettings.rightShelfSlope
				: 100;

			// const d = JSON.parse(JSON.stringify(this.property.settings));
			// window["viewer"].updateTrenchSettings(this.id, d);
		},
		updateTrenchMaterial() {
			window["viewer"].updateTrenchMaterialSettings(
				this.id,
				{
					surfaceColor: this.property.settings.commonSettings.surfaceColor,
				},
				{
					color: this.property.settings.cutSettings.color,
					transparency: this.property.transparency,
				},
			);
		},
		setDefaultSpecifiedAltitude() {
			const specifiedAltitude = window[
				"viewer"
			].getAverageHeightOfFirstAndLastPointOfTrench(this.id);
			if (specifiedAltitude) {
				this.property.settings.commonSettings.destination.specifiedAltitude =
					+specifiedAltitude.toFixed(3);
			}
		},
	},
};
</script>
